<template>
    <div class="order_manage_view content-wrap">
      <div class="content-select">
        <div class="select-content">
          <el-form
            ref="selectFrom"
            :model="queryParams"
            class="select-form"
            :inline="true"
            label-width="120px"
          >
            <el-form-item label="销方名称" >
              <el-select
              clearable
                v-model="queryParams.orgId"
                @change="handleChangeTaxNo">
                <el-option v-for="organ in organsList"
                      :key="organ.id"
                      :label="organ.name"
                      :value="organ.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="数电账户" >
                <el-select
                clearable
                    v-model="queryParams.username"
                >
                    <el-option
                    v-for="account in accounts"
                    :key="account.id"
                    :label="account.payer + ' ' + account.elecAccount"
                    :value="account.elecAccount"
                    >
                    <span>{{ account.payer + ' ' + account.elecAccount }}</span>
                    <span v-if="account.loginState" class="online-indicator"></span>
                    <span v-else class="offline-indicator"></span>
                    </el-option>
                </el-select>
            </el-form-item>
  
            <el-form-item
              label="开票时间"
              for="tradeTime"
            >
              <el-date-picker
                v-model="queryParams.tradeTime"
                type="daterange"
                range-separator="~"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyyMMdd"
                :picker-options="pickerOptions"
                @keyup.enter.native="handleQuery"
                size="mini"
                style="width: 200px"
                clearable
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item
              label="发票类型"
              for="fplxdm"
            >
              <el-select
                v-model="queryParams.fplxdmList"
                placeholder="请选择开票类型"
                @keyup.enter.native="handleQuery"
                clearable
                multiple
                collapse-tags
              >
                <el-option
                  v-for="item in dictionary.fplxType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="同步类型">
              <el-select
              clearable
                v-model="queryParams.gjbq">
                <el-option 
                  label="取得发票"
                  value="1"></el-option>
                <el-option  label="开具发票"
                value=""></el-option>
              </el-select>

            </el-form-item>
            <el-form-item style="margin-left: 32px">
                <el-button type="default" @click="handleReset">重置</el-button>
                <el-button type="primary" @click="handleQuery">查询</el-button>
                <el-button type="primary">修复全部</el-button>
              <!-- <select-button
                @select="handleQuery"
                @reset="handleReset"
              ></select-button> -->
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="content-main">
        <div>
          <div class="main-top">
            <div class="top-left">
              <el-popover placement="right" width="80" trigger="click">
                <el-checkbox
                  :indeterminate="isIndeterminate"
                  v-model="checkAll"
                  @change="handleCheckAllChange"
                  >全选
                </el-checkbox>
                <div class="part"></div>
                <el-checkbox
                  v-for="(pv, pk) in columns"
                  :label="pv.label"
                  :key="pk"
                  v-model="pv.visible"
                  style="display: block; margin-top: 6px"
                  @change="handleCheckedCitiesChange(pk, pv)"
                />
                <div slot="reference" class="rowSet">
                  <img class="imgSet" src="@/assets/icon/set.png" />
                  <span>列设置</span>
                </div>
              </el-popover>
            </div>
          </div>
          <div class="content-table">
            <el-table
              ref="invoiceCenterTable"
              :data="dataList"
              stripe
              border
              v-loading="dataLoading"
              :header-cell-style="handleHeaderCellStyle"
            >
              <el-table-column
                type="index"
                width="80"
                label="序号"
                fixed="left"
                align="center"
              />
              <el-table-column
                v-if="columns['fphm'].visible"
                prop="fphm"
                :label="columns['fphm'].label"
                min-width="200"
                fixed="left"
                show-overflow-tooltip
              />
              <el-table-column
                v-if="columns['kprq'].visible"
                prop="kprq"
                fixed="left"
                :label="columns['kprq'].label"
                min-width="160"
              />
              <el-table-column
                v-if="columns['fplxdm'].visible"
                prop="fplxdm"
                :label="columns['fplxdm'].label"
                min-width="200"
                :formatter="fmtFplxdm"
                show-overflow-tooltip
              />
              <el-table-column
                v-if="columns['hjje'].visible"
                prop="hjje"
                :label="columns['hjje'].label"
                min-width="160"
                :formatter="fmtDataAmount"
              />
              <el-table-column
                v-if="columns['hjse'].visible"
                prop="hjse"
                :label="columns['hjse'].label"
                width="180"
                align="right"
                 :formatter="fmtDataAmount"
              />
              <el-table-column
                v-if="columns['jshj'].visible"
                prop="jshj"
                :label="columns['jshj'].label"
                width="140"
                header-align="right"
                align="right"
                :formatter="fmtDataAmount"
              />
              <el-table-column
                v-if="columns['fpzt'].visible"
                prop="fpzt"
                :label="columns['fpzt'].label"
                width="180"
                header-align="right"
                align="center"
                :formatter="fmtFpzt"
              />
              <el-table-column
                v-if="columns['kpr'].visible"
                prop="kpr"
                :label="columns['kpr'].label"
                min-width="200"
                show-overflow-tooltip
              />
              <el-table-column
                v-if="columns['yfphm'].visible"
                prop="yfphm"
                :label="columns['yfphm'].label"
                width="180"
              />
              <el-table-column
                v-if="columns['gfkpmc'].visible"
                prop="gfkpmc"
                :label="columns['gfkpmc'].label"
                min-width="160"
                
              />
              <el-table-column
                v-if="columns['gfkpsh'].visible"
                prop="gfkpsh"
                :label="columns['gfkpsh'].label"
                header-align="right"
                align="right"
                width="100"
              />
              <el-table-column
                v-if="columns['xfkpmc'].visible"
                prop="xfkpmc"
                :label="columns['xfkpmc'].label"
                width="180"
              />
              <el-table-column
                v-if="columns['xfkpsh'].visible"
                prop="xfkpsh"
                :label="columns['xfkpsh'].label"
                width="180"
              />
              <el-table-column
                v-if="columns['operate'].visible"
                prop="operate"
                :label="columns['operate'].label"
                fixed="right"
                width="100"
                align="center"
              >
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    @click="handleRepaire(scope.row)"
                    >修复</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="content-page">
          <el-pagination
            background
            @size-change="handleSizeChange"
            :current-page.sync="pageParams.pageNo"
            @current-change="handleCurrentChange"
            :page-sizes="[20, 30, 50, 100]"
            :page-size.sync="pageParams.pageSize"
            layout="total, sizes, prev, pager, next"
            :total="dataAggr.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import OrganizationSelect from "@/components/widgets/OrganizationSelect";
  import {
    getList,getOrgByUser,repaireInv
  } from "@/service/sal/repaire.js";
  import { fmtMoney, fmtCurrency } from "@/assets/js/format-util";
  import {
    tableStyle,
    pagingMethods,
    dateOption,
    changeMethods,
  } from "@/util/mixins";
  import { getAccountList } from '@/service/rpa/rpa-auth';
  export default {
    name: "SalInvoicingTradeList",
    mixins: [tableStyle, pagingMethods, dateOption, changeMethods],
    components: { OrganizationSelect },
    data() {
      return {
        columns: {
          fphm: {
            label: "发票号码",
            visible: true,
          },
          kprq: {
            label: "开票日期",
            visible: true,
          },
          fplxdm: {
            label: "发票类型",
            visible: true,
          },
          hjje: {
            label: "合计金额",
            visible: true,
          },
          hjse: {
            label: "合计税额",
            visible: true,
          },
          jshj: {
            label: "价税合计",
            visible: true,
          },
          fpzt: {
            label: "发票状态",
            visible: true,
          },
          yfphm: {
            label: "原发票号码",
            visible: true,
          },
          kpr: {
            label: "开票人",
            visible: true,
          },
          gfkpmc: {
            label: "购方开票名称",
            visible: true,
          },
          gfkpsh: {
            label: "购方开票税号",
            visible: true,
          },
          xfkpmc: {
            label: "销方开票名称",
            visible: true,
          },
          xfkpsh: {
            label: "销方开票税号",
            visible: true,
          },
          operate: {
            label: "操作",
            visible: true,
          },
        },
        organs:{
            orgId:undefined,
            taxno:undefined,
            name:undefined
        },
        queryParams: {
          orgId: undefined,
          nsrsbh: undefined,
          fplxdmList: [],
          tradeTime: [],
          qqsj: undefined,
          zzsj: undefined,
          username: undefined,
          fplxdm:undefined,
          gjbq:"",
          page:1,
          size:20,
        },
        repaireParams:{

        },
        pageParams: {
          pageNo: 1,
          pageSize: 20,
        },
  
        pickerOptions: {
          disabledDate: (time) => {
            return time.getTime() >= Date.now();
          },
        },
        pickerDisabled: {
          disabledDate(date) {
            return date.getTime() > Date.now();
          },
        },
  
        dictionary: {
          fplxType: [],
          fpztList:[],
        },
        dictionaryLoading: false,
  
        isIndeterminate: true,
        checkAll: false,
  
        dataList: [],
        organsList:[],
        accounts:[],
        dataLoading: false,
        currentAccount:null,
      
        dataAggr: {
          total: 0,
        },
      };
    },
    async created() {
      // this.queryParams.orgId = JSON.parse(localStorage.getItem("orgId" || ""));
      await this.initDict();
      this.getOrg()
      // this.handleQuery();
      
    },
    methods: {
      // 初始字典数据
      async initDict() {
        this.dictionaryLoading = true;

        const { success, data } = await this.batchGetDicts({
          types: ["repaire_fplx","repaire_fpzt"],
        });
        if (success) {
          this.dictionary.fplxType = data["repaire_fplx"];
          this.dictionary.fpztList = data["repaire_fpzt"];
        }
        this.dictionaryLoading = false;
      },
      //发票修复
       async handleRepaire(row){
            //todo
          this.repaireParams.fphm=row.fphm
          this.repaireParams.fpdm=row.fpdm
          // const { success,data }=await repaireInv(this.repaireParams);
          // if(success){
          //   console.log("发票修复结果"+data)
          // }

      },
       handleChangeTaxNo(item){
        console.log("切换的税号",item)
        if(item != ""){
          this.getAccount(item)
        }
        else{
          this.accounts=[]
        }

        //todo
      },
      //获取当前用户所属角色的所有数据权限且有税号的组织列表并集
       getOrg(){
        getOrgByUser().then((res)=>{
          if(res.success){
            this.organsList=res.data
          }
          
        })
      },
      // 列头设置全选
      handleCheckAllChange(val) {
        this.isIndeterminate = false;
        let cols = this.columns;
        for (let p in cols) {
          cols[p].visible = val;
        }
        localStorage.setItem("otherInvoiceLineSet", JSON.stringify(this.columns));
      },
      // 列头设置改变
      handleCheckedCitiesChange(pk, pv) {
        Object.keys(this.columns).some((key) => {
          if (pk == key) {
            this.columns[key].visible = pv.visible;
            return true;
          }
        });
        localStorage.setItem("otherInvoiceLineSet", JSON.stringify(this.columns));
      },
      //根据选择的组织 获取数电账户
      async getAccount(orgId) {
        this.currentAccount=null
        this.accounts=[]
        const { success, data } = await getAccountList(orgId);
        if (success ) {
          if(Array.isArray(data) && data.length>0){
            this.accounts = data;
            console.log(this.accounts);
            this.currentAccount = JSON.parse(JSON.stringify(data[0]));
            // this.selectForm.orgId = data[0].payer; // 初始化选中的账户
          //   localStorage.setItem('eleAccount', this.currentAccount.elecAccount);
          }
          else{
            this.toast('该组织没有维护数电账户，请先维护数电账户', 'error');
          }
        }
    },
      // 订单列表查询
      handleQuery() {
        
        // this.getOrg();
        if(this.queryParams.orgId == ""){
          this.toast('请选择销方名称', 'warning')
          return;
        }
        if(this.queryParams.username ==""){
          this.toast('请选择数电账户','warning')
          return;
        }
        if(this.queryParams.tradeTime.length<=0){
          this.toast('请选择开票时间','warning')
          return;
        }
        if(this.queryParams.fplxdmList.length<1){
          this.toast('请选择发票类型','warning')
          return;
        }
        this.queryParams.fplxdm = this.queryParams.fplxdmList.join(',');
        console.log(this.queryParams.fplxdm);      
        this.queryParams.qqsj=this.queryParams.tradeTime[0]
        this.queryParams.zzsj=this.queryParams.tradeTime[1]
        this.queryParams.pageNo = 1;
        let org=this.organsList.filter(o =>o.id === this.queryParams.orgId)
        this.queryParams.nsrsbh=org[0].taxNo
        console.log("查询条件："+JSON.stringify(this.queryParams))
        console.log("选中的组织",JSON.stringify(this.organs))
        this.repaireParams=this.queryParams
        this.getRepaireList();
     
      },
      async getRepaireList(){
          this.dataLoading=true;
          this.queryParams.page=this.pageParams.pageNo
          this.queryParams.size=this.pageParams.pageSize;
          const { success,data }=await getList(this.queryParams);
          console.log("查询可修复的发票列表返回值：",data)
          if(success){
            this.dataList=data.records
            this.dataAggr.total=data.total
          }
          this.dataLoading = false;
      },
      // 分页查询订单
      // async pagingInvoicingList() {
      //   this.dataLoading = true;
      //   if (this.queryParams.orgId > 0) {
      //     this.queryParams.sellerOrgId = this.queryParams.orgId;
      //   }
      //   let pageArg = Object.assign({}, this.queryParams, this.pageParams);
      //   const { success, data } = await pageInvoicingTrade(pageArg);
      //   if (success) {
      //     this.dataList = data.list;
      //   }
        
      // },
      // 重置查询条件
      handleReset() {
        this.queryParams = {
          orgId: undefined,
          nsrsbh: undefined,
          fplxdm: undefined,
          fplxdmList:[],
          tradeTime: [],
          qqsj: undefined,
          zzsj: undefined,
          username: undefined,
          gjbq:"",
          page:1,
          size:20,
        };
      },
      // 切换分页条数
      handleSizeChange(val) {
        this.queryParams.page = 1;
        this.queryParams.size = val;
        // this.pagingInvoicingList();
        this.getRepaireList()
      },
      // 列表翻页
      handleCurrentChange(val) {
        this.queryParams.page = val;
        this.getRepaireList();
      },
      //格式化发票类型
      fmtFplxdm(row,column,value){
        return this.getDictLabel(this.dictionary.fplxType,value);
      },
      // 格式开票类型
      fmtBillingType(row, column, value) {
        return this.handleValueToLabel("BillingType", value);
      },
      fmtFpzt(row,column,value){
        return this.getDictLabel(this.dictionary.fpztList,value);
      },
      // 格式金额数据
      fmtDataAmount(row, column, value) {
        return fmtCurrency(value, 2, "", ",", ".", "");
      },
    },
  };
  </script>
  <style lang="scss" scoped>
  .order_manage_view {
    .table_total_box {
      display: flex;
      padding: 16px 10px;
      background: #ebf4ff;
      span {
        padding-right: 40px;
      }
    }
  }
  
  @import "@/style/select.scss";
  
  .select-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #e9e9e9;
    padding: 16px 24px;
  
    .select-items {
      display: inline-flex;
  
      div {
        color: #3d94ff;
        margin-right: 20px;
        font-weight: 500;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        line-height: 22px;
  
        img {
          width: 20px;
          height: 20px;
          vertical-align: sub;
          margin-right: 4px;
        }
      }
  
      div:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  
    .select-title span:before {
      content: "|";
      display: inline-block;
      font-size: 16px;
      margin-right: 10px;
      background-color: #333;
    }
  }
  
  .content-main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100% - 180px);
  
    .main-top {
      display: flex;
      justify-content: space-between;
      padding-bottom: 12px;
  
      .top-left {
        display: flex;
        justify-content: flex-start;
  
        span {
          line-height: 32px;
          font-size: 16px;
          color: #666666;
        }
  
        .el-button {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #1890ff;
          line-height: 22px;
        }
  
        .rowSet {
          display: flex;
          align-items: center;
          cursor: pointer;
  
          .imgSet {
            width: 20px;
            height: 20px;
            margin-right: 2px;
          }
  
          span {
            color: #1890ff;
          }
        }
      }
    }
  
    .content-table {
      .el-button {
        padding: 0;
        margin-right: 10px;
      }
    }
  }
  
  .manual-input {
    .el-row {
      margin-bottom: 20px;
  
      &:last-child {
        margin-bottom: 0;
      }
    }
  
    .el-col {
      text-align: left;
      border-radius: 4px;
  
      ::v-deep .el-select > .el-input,
      .el-input {
        width: 250px;
      }
    }
  }
  
  .phone-scan {
    .phone-scan-text {
      display: flex;
      text-align: left;
      flex-direction: column;
      align-items: flex-start;
  
      span {
        margin-bottom: 12px;
      }
    }
  
    .phone-scan-img {
      .el-image {
        width: 260px;
        height: 260px;
      }
    }
  }
  
  .invoice-update {
    .el-row {
      margin-bottom: 20px;
  
      &:last-child {
        margin-bottom: 0;
      }
    }
  
    .el-col {
      text-align: left;
      border-radius: 4px;
    }
  }
  
  .el-image-viewer {
    position: absolute;
    z-index: 1000;
  }
  
  .dialog-title {
    display: flex;
    justify-content: space-between;
    margin-top: -24px;
    margin-bottom: 24px;
  
    .title {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 24px;
    }
  
    .message {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
    }
  }
  
  .dialog-delete {
    display: flex;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 26px;
  }
  
  .dialog-invoice-list {
    .list-statistics {
      text-align: left;
  
      .el-row {
        margin-bottom: 12px;
      }
  
      label {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
      }
  
      span {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 20px;
      }
    }
  
    .list-table {
      margin-top: 12px;
  
      div {
        text-align: left;
        margin-bottom: 12px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 20px;
      }
  
      .el-button {
        padding: 0;
      }
    }
  }
  
  .dialog-settlement {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 26px;
    text-align: left;
  }
  
  ::v-deep .el-dialog__header {
    padding: 24px 24px 0px 24px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 24px;
  }
  
  ::v-deep .el-dialog__body {
    padding: 24px 20px;
    color: #333333;
    font-size: 14px;
    word-break: break-all;
  }
  
  ::v-deep .el-form-item__error {
    padding-top: 0;
  }
  
  ::v-deep .el-dialog__footer {
    padding: 0px 20px 20px;
    text-align: right;
    box-sizing: border-box;
  }
  
  .invoice-deleted {
    position: absolute;
    bottom: 0;
    margin-left: -24px;
    height: 48px;
    z-index: 9;
    width: calc(100% - 200px);
    line-height: 48px;
    box-shadow: 0px -4px 8px 0px rgba(0, 0, 0, 0.1);
  }
  
  .invoice-deleted-hidden {
    position: absolute;
    bottom: 0;
    margin-left: -24px;
    height: 48px;
    z-index: 9;
    width: calc(100% - 64px);
    line-height: 48px;
    box-shadow: 0px -4px 8px 0px rgba(0, 0, 0, 0.1);
  }
  
  .dialog-wrap {
    span {
      color: #333333;
    }
  
    .deletes-btns {
      padding-top: 24px;
      text-align: right;
    }
  }
  
  .part {
    width: 100%;
    height: 1px;
    background: #e5e5e5;
    margin: 15px 0;
  }
  
  .icon {
    color: #ff0000;
    margin-left: 3px;
  }
  
  .button-footer {
    text-align: center;
  
    .button-left {
      margin-right: 65px;
    }
  }
  
  .el-dropdown-link {
    cursor: pointer;
    color: #409eff;
  }
  
  .el-icon-arrow-down {
    font-size: 12px;
  }
  
  .train21No {
    margin-right: 31px;
    margin-left: -31px;
    .el-input {
      width: 150px;
    }
  }
  
  .select-content {
    ::v-deep .select-form {
      width: auto;
      display: block;
    }
  
    ::v-deep .select-form > div {
      display: inline-flex;
    }
  }
  
  ::v-deep .el-range-editor--mini .el-range-separator {
    line-height: inherit;
  }
  </style>
  