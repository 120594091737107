import { get, post } from "@/service/index.js";

// 开票订单-分页
export const getList = (param) => {
  return get("/repaire/list", param).then((res) => res);
};
export const getOrgByUser = () => {
  return get("/organization/getOrgByUserId").then((res) => res);
};
export const repaireInv = (param) =>{
  return get("/repaire/repaire",param).then ((res) => res);
}